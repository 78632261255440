//return all products
export const PRODUCT_LIST_REQUEST = "PRODUCT_LIST_REQUEST";
export const PRODUCT_LIST_SUCCESS = "PRODUCT_LIST_SUCCESS";
export const PRODUCT_LIST_FAIL = "PRODUCT_LIST_FAIL";

//return individual product
export const PRODUCT_DETAILS_REQUEST = "PRODUCT_DETAILS_REQUEST";
export const PRODUCT_DETAILS_SUCCESS = "PRODUCT_DETAILS_SUCCESS";
export const PRODUCT_DETAILS_FAIL = "PRODUCT_DETAILS_FAIL";

//return inventory from individual product
export const PRODUCT_REMOVE_INVENTORY_REQUEST =
  "PRODUCT_REMOVE_INVENTORY_REQUEST";
export const PRODUCT_REMOVE_INVENTORY_SUCCESS =
  "PRODUCT_REMOVE_INVENTORY_SUCCESS";
export const PRODUCT_REMOVE_INVENTORY_FAIL = "PRODUCT_REMOVE_INVENTORY_FAIL";

//delete individual product
export const PRODUCT_DELETE_REQUEST = "PRODUCT_DELETE_REQUEST";
export const PRODUCT_DELETE_SUCCESS = "PRODUCT_DELETE_SUCCESS";
export const PRODUCT_DELETE_FAIL = "PRODUCT_DELETE_FAIL";

//create individual product
export const PRODUCT_CREATE_REQUEST = "PRODUCT_CREATE_REQUEST";
export const PRODUCT_CREATE_SUCCESS = "PRODUCT_CREATE_SUCCESS";
export const PRODUCT_CREATE_FAIL = "PRODUCT_CREATE_FAIL";
export const PRODUCT_CREATE_RESET = "PRODUCT_CREATE_RESET";

//update individual product
export const PRODUCT_UPDATE_REQUEST = "PRODUCT_UPDATE_REQUEST";
export const PRODUCT_UPDATE_SUCCESS = "PRODUCT_UPDATE_SUCCESS";
export const PRODUCT_UPDATE_FAIL = "PRODUCT_UPDATE_FAIL";
export const PRODUCT_UPDATE_RESET = "PRODUCT_UPDATE_RESET";

//update individual product
export const PRODUCT_FEATURED_REQUEST = "PRODUCT_FEATURED_REQUEST";
export const PRODUCT_FEATURED_SUCCESS = "PRODUCT_FEATURED_SUCCESS";
export const PRODUCT_FEATURED_FAIL = "PRODUCT_FEATURED_FAIL";
